import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderMenu extends Component {
  render() {
    return (
      <>
        <ul className="nav navbar-nav">
          <li className="active">
            <Link to={"/"}>
              <span className="ti-home">{" "}Home</span>
            </Link>
          </li>
          <li>
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
          </li>
          <li>
            <Link to={"/help-support"}>Help & Support</Link>
          </li>
        </ul>
      </>
    );
  }
}
export default HeaderMenu;
