import React from "react";
import { Link } from "react-router-dom";
import "../../../css/privacy-policy.css";

const Content = () => {
    return (
        <>
            <div className="page-content bg-white">
                <div className="content-block">
                    <div className="section-full content-inner-2">
                        <div className="container">
                            <div className="section-head text-black text-center">
                                <h4 className="text-gray-dark text-center">Help & Support</h4>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 p-2">
                                    <div className="widget shadow-box">
                                        <h4 className="m-b30 text-black">
                                            Getting Started
                                        </h4>
                                        <p className="text-capitalize m-b20">
                                            <Link>How to sign up for AnySystem?</Link>
                                        </p>
                                        <p className="text-capitalize m-b20">
                                            <Link>What is AnySystem?</Link>
                                        </p>
                                        <p className="text-capitalize m-b20">
                                            <Link>Plan and Pricing</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 p-2">
                                    <div className="widget shadow-box">
                                        <h4 className="m-b30 text-black">
                                            Manage My Account
                                        </h4>
                                        <p className="text-capitalize m-b20">
                                            <Link>Can't sign in to AnySystem</Link>
                                        </p>
                                        <p className="text-capitalize m-b20">
                                            <Link>AnySystem account email was changed without permission</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 p-2">
                                    <div className="widget shadow-box">
                                        <h4 className="m-b30 text-black">
                                            Unable To Scan?
                                        </h4>
                                        <p className="text-capitalize m-b20">
                                            <Link>Try To Give Camera Permission Of AnySystem</Link>
                                        </p>
                                        <p className="text-capitalize m-b20">
                                            <Link>Does QR Code Expired ?</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Content;