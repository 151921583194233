import React from "react";
import Content from "../Components/Home/Content";

const Home = () => {
  return (

    <div className="page-content bg-white rubik-font">
      <div className="owl-slider-banner main-slider">
        <Content />
      </div>
    </div>
  );
};

export default Home;
