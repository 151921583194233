import React from "react";
import Content from "../Components/PrivacyPolicy/Content";

const PrivacyPolicy = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default PrivacyPolicy;
