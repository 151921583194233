import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./Pages/Home";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ScrollToTop from "./Element/ScrollToTop";
import Header from "./Layout/header";
import Footer from "./Layout/footer";
import Error404 from "./Pages/Error/Error404";
import HelpSupport from "./Pages/HelpSupport";

const Markup = () => {
  return (
    <>
      <Header />
      <div className="page-wraper">
        <Routes>
          <Route index={true} path="/" element={<Home />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/help-support" exact element={<HelpSupport />} />
          <Route path="*" exact component={<Error404 />} />
        </Routes>
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Markup;
