import React from "react";
import Content from "../Components/Support/Content";

const HelpSupport = () => {
    return (
        <>
            <Content />
        </>
    );
};

export default HelpSupport;
